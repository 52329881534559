<template>
  <div class="customize-main">
    <table class="customize-form" border="0" cellpadding="0" cellspacing="0">
      <tbody>
        <tr>
          <td colspan="2">
            <img class="teding" src="@/assets/image/teding.jpg" />
          </td>
          <td class="customize-title1" colspan="16">
            Record of Foreign Affairs Application <br />
            中石化南京工程中东公司外事业务办理记录
          </td>
        </tr>
        <tr>
          <td class="text-center">
            序号 <br />
            SN
          </td>
          <td class="text-center">
            Name in Chinese<br />
            中文姓名
          </td>
          <td class="text-center">
            Name in English<br />
            拼音姓名
          </td>
          <td class="text-center">
            Residence Address <br />
            户籍地址
          </td>
          <td class="text-center">
            Gender<br />
            性别
          </td>
          <td class="text-center">
            Nationality<br />
            国籍
          </td>
          <td class="text-center">
            ID of Home Country<br />
            员工母国身份证号
          </td>
          <td class="text-center">
            Project Name<br />
            项目名称
          </td>
          <td class="text-center">
            SubProject Name<br />
            分项目名称
          </td>
          <td class="text-center">
            Working Unit<br />
            用工单位
          </td>
          <td class="text-center">
            Job Profession<br />
            岗位名称
          </td>
          <td class="text-center underline-text">
            Passport No<br />
            护照号
          </td>
          <td class="text-center">
            IQAMA/ID No.<br />
            暂住证号
          </td>
          <td class="text-center">SPONSOR</td>
          <td class="text-center">
            入沙时间<br />
            Entry Date
          </td>
          <td class="text-center">
            离沙时间 <br />
            Exit Date
          </td>
          <td class="text-center">
            Abnormal Exit reasons <br />
            非正常离境原因
          </td>
          <td class="text-center">
            Remark <br />
            备注
          </td>
        </tr>
        <tr v-for="(item, index) in CostAllocationDetails" :key="index">
          <td class="text-center">
            {{ index + 1 }}
          </td>
          <td class="text-center">
            {{ item.NameinChinese }}
          </td>
          <td class="text-center">
            {{ item.NameinEnglish }}
          </td>
          <td class="text-center">
            {{ item.ResidenceAddress }}
          </td>
          <td class="text-center">
            {{ item.Gender | labelFormater(options) }}
          </td>
          <td class="text-center">
            {{ item.Nationality | nationalityFormater(Countries) }}
          </td>
          <td class="text-center">
            {{ item.IDofHomeCountry }}
          </td>
          <td class="text-center">
            {{ item.Project | projectFormater(projectList) }}
          </td>
          <td class="text-center">
            {{ item.SubProject | projectFormater(item.subProjectList) }}
          </td>
          <td class="text-center">
            {{ item.WorkingUnit | companyFormater(companyList) }}
          </td>
          <td class="text-center">
            {{ item.JobProfession | positionFormater(positionList) }}
          </td>
          <td class="text-center">
            <el-input
              v-model="item.PassportCode"
              @change="changePassportCode(index)"
              @blur="changePassportCode(index)"
              placeholder="护照号"
            ></el-input>
          </td>
          <td class="text-center">
            {{ item.IQAMACode }}
          </td>
          <td class="text-center">
            {{ item.SPONSOR | labelFormater(SPONSOR) }}
          </td>
          <td class="text-center">
            <el-date-picker
              value-format="yyyy-MM-dd"
              format="yyyy-MM-dd"
              v-model="item.EntryDate"
              type="date"
              placeholder="入沙时间"
            >
            </el-date-picker>
          </td>
          <td class="text-center">
            <el-date-picker
              value-format="yyyy-MM-dd"
              format="yyyy-MM-dd"
              v-model="item.ExitDate"
              type="date"
              placeholder="离沙时间"
            >
            </el-date-picker>
          </td>
          <td class="text-center">
            <el-input
              v-model="item.AbnormalExitReasons"
              placeholder="非正常离境原因"
            ></el-input>
          </td>
          <td class="text-center">
            <el-input v-model="item.Remark" placeholder="备注"></el-input>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import {
  getProjectAll,
  getCompanyAll,
  allPosition,
  getProjectsChildren,
} from "@/api/user";
import {
  addBlackItem,
  updateBlackItem,
  getGeneralBasic,
  getUsersCode,
} from "@/api/table";
export default {
  filters: {
    projectFormater(id, data) {
      const item = data.find((item) => {
        return item.ProjectID === id;
      });
      return item ? item.ProjectName : "";
    },
    companyFormater(id, data) {
      const item = data.find((item) => {
        return item.ID === id;
      });
      return item ? item.CompanyCHS : "";
    },
    positionFormater(id, data) {
      const item = data.find((item) => {
        return item.ID === id;
      });
      return item ? item.PositionsCHS : null;
    },
    labelFormater(id, data) {
      const item = data.find((item) => {
        return item.value === id;
      });
      return item ? item.label : null;
    },
    nationalityFormater(id, data) {
      const item = data.find((item) => {
        return item.ID === id;
      });
      return item ? item.CountryNameCHS : null;
    },
  },
  data() {
    return {
      CostAllocationDetails: [],
      options: [
        {
          value: 1,
          label: "男",
        },
        {
          value: 2,
          label: "女",
        },
      ],
      SPONSOR: [
        {
          label: "SNEI",
          value: 1,
        },
        {
          label: "SEG",
          value: 2,
        },
        {
          label: "其它",
          value: 3,
        },
      ],
      Countries: [],
      projectList: [],
      companyList: [],
      positionList: [],
      costAllocationItem: {
        ID: null,
        NameinChinese: null,
        NameinEnglish: null,
        Gender: null,
        Nationality: null,
        IDofHomeCountry: null,
        ResidenceAddress: null,
        Project: null,
        SubProject: null,
        WorkingUnit: null,
        JobProfession: null,
        PassportCode: null,
        IQAMACode: null,
        SPONSOR: null,
        EntryDate: null,
        ExitDate: null,
        AbnormalExitReasons: null,
        Remark: null,
        subProjectList: [],
      },
    };
  },

  methods: {
    addCostAllocation(type) {
      const data = [];
      let isTrue = false;
      this.CostAllocationDetails.forEach((item) => {
        if (item.PassportCode) {
          data.push(JSON.parse(JSON.stringify(item)));

          if (item.EntryDate && item.ExitDate) {
            const bool = this.compareDates(item.ExitDate, item.EntryDate);
            if (!bool) {
              isTrue = true;
            }
          }
        }
      });

      if (isTrue) {
        this.$message.warning("离沙日期必须大于入沙日期");
        return;
      }

      if (type === 1) {
        addBlackItem({ list: data }).then((res) => {
          if (res.status === 200) {
            this.$message.success("新建成功");
            this.$emit("update");
            this.$emit("close");
          }
        });
      } else {
        updateBlackItem({ list: data }).then((res) => {
          if (res.status === 200) {
            this.$message.success("编辑成功");
            this.$emit("update");
            this.$emit("close");
          }
        });
      }
    },
    geteditData(params) {
      console.log("params", params);
      if (params.EntryDate) {
        params.EntryDate = params.EntryDate.slice(0, 10);
      }
      if (params.ExitDate) {
        params.ExitDate = params.ExitDate.slice(0, 10);
      }
      params.subProjectList = [];
      this.CostAllocationDetails[0] = JSON.parse(JSON.stringify(params));
      this.getProjectsChildren(params.Project, 0);
      this.CostAllocationDetails = this.CostAllocationDetails.concat();
      console.log("111");
    },
    clearData() {
      this.CostAllocationDetails = [];
      for (let i = 0; i < 10; i++) {
        const costAllocationItem = JSON.parse(
          JSON.stringify(this.costAllocationItem)
        );
        this.CostAllocationDetails.push(costAllocationItem);
      }
    },
    async changePassportCode(index) {
      let PassportCode = this.CostAllocationDetails[index].PassportCode;
      let userInfo = null;
      await getUsersCode({ code: PassportCode, codeType: 1 }).then((res) => {
        if (res.status === 200 && res.response) {
          userInfo = res.response;
        }
      });
      if (!userInfo) {
        return;
      }
      this.CostAllocationDetails[index].NameinChinese = userInfo.ChineseName;
      this.CostAllocationDetails[index].NameinEnglish = userInfo.EnglishName;
      this.CostAllocationDetails[index].Gender = userInfo.Gender;
      this.CostAllocationDetails[index].Nationality = userInfo.CountryID;
      this.CostAllocationDetails[index].IDofHomeCountry = userInfo.IDNumber;

      this.CostAllocationDetails[index].ResidenceAddress =
        userInfo.ResidenceAddress;
      this.CostAllocationDetails[index].Project = userInfo.ProjectID;
      this.CostAllocationDetails[index].SubProject = userInfo.SubProjectID;
      this.CostAllocationDetails[index].WorkingUnit = userInfo.CompanyID;
      this.CostAllocationDetails[index].JobProfession = userInfo.PositionID;

      this.CostAllocationDetails[index].IQAMACode = userInfo.IQAMAID;
      this.CostAllocationDetails[index].SPONSOR = userInfo.Sponsor;
      this.getProjectsChildren(userInfo.ProjectID, index);
    },
    changeProjectID(index) {
      this.CostAllocationDetails[index].SubProject = null;
      this.getProjectsChildren(
        this.CostAllocationDetails[index].Project,
        index
      );
    },
    getProjectsChildren(id, index) {
      getProjectsChildren({ id }).then((res) => {
        if (res.status === 200) {
          this.CostAllocationDetails[index].subProjectList = res.response;
        }
      });
    },
    compareDates(date1, date2) {
      var time1 = new Date(date1).getTime();
      var time2 = new Date(date2).getTime();

      if (time1 > time2) {
        return 1; // date1 大于 date2
      } else if (time1 < time2) {
        return -1; // date1 小于 date2
      } else {
        return 0; // date1 等于 date2
      }
    },
  },
  created() {
    for (let i = 0; i < 10; i++) {
      const costAllocationItem = JSON.parse(
        JSON.stringify(this.costAllocationItem)
      );
      this.CostAllocationDetails.push(costAllocationItem);
    }
    getGeneralBasic().then((res) => {
      if (res.status === 200) {
        this.Countries = res.response.Countries;
      }
    });
    getProjectAll().then((res) => {
      if (res.status === 200) {
        this.projectList = res.response;
      }
    });
    getCompanyAll().then((res) => {
      if (res.status === 200) {
        this.companyList = res.response;
      }
    });
    allPosition().then((res) => {
      if (res.status === 200) {
        this.positionList = res.response;
      }
    });
  },
};
</script>
<style lang="scss" scoped>
.customize-main {
  width: 100%;
  color: #000;
  .teding {
    max-width: 120px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }
  .customize-title1 {
    font-size: 24px;
    text-align: center;
    color: #000;
    font-weight: bolder;
    padding: 5px;
  }
  .customize-herder {
    display: flex;
    justify-content: space-around;
    font-size: 16px;
    color: #000;
    height: 36px;
    align-items: center;
    margin-top: 12px;
  }
  .customize-footer {
    display: flex;
    justify-content: space-around;
    font-size: 16px;
    color: #000;
    height: 36px;
    align-items: center;
    margin-top: 24px;
    .text-box {
      text-align: center;
      div {
        margin-bottom: 8px;
      }
    }
  }
  .customize-form {
    font-size: 11px;
    width: 100%;
    border-left: 1px solid black;
    border-top: 1px solid black;
    margin-top: 12px;
    tr td {
      border-right: 1px solid black;
      border-bottom: 1px solid black;
      min-width: 50px;
    }
    .text-center {
      text-align: center;
      font-size: 13px;
      padding: 5px;
      .el-select {
        max-width: 100%;
      }
      .el-input {
        max-width: 100%;
      }
      .el-date-editor {
        width: 100%;
      }
      .el-cascader {
        width: 100%;
      }
    }
    .text-title {
      text-align: center;
      font-size: 13px;
      .title {
        height: 30px;
        line-height: 30px;
        border-bottom: 1px solid #e0e0e0;
      }
      .img-box {
        padding: 10px;
        min-height: 100px;
      }
      .img-box >>> img {
        width: 100%;
      }
    }
    .text-right {
      text-align: right;
      font-size: 13px;
      padding: 5px;
    }
    .text-left {
      text-align: left;
      font-size: 10px;
      padding: 5px;
    }
  }
  .customize-form2 {
    border-top: none;
    margin-top: 0;
  }
}
</style>
<style scoped>
/* .customize-main >>> .el-input__inner { */
/* border: none; */
/* } */
.input-box >>> .el-input__inner {
  border: none;
  padding: 0;
}
.text-size >>> .el-checkbox__input {
  margin-right: 10px;
}
.signature {
  max-width: 100px;
}
.felx-box {
  display: flex;
  align-items: center;
}
.img-box {
  max-width: 100px;
  margin-left: 10px;
}
.img-box >>> img {
  width: 100%;
}
</style>
<template>
  <div class="main">
    <div class="page-box">
      <div class="page-title">不良人员</div>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item class="breadcrumb-active"
          ><i class="fa fa-dashboard"></i>工作台</el-breadcrumb-item
        >
        <el-breadcrumb-item class="breadcrumb-active">
          不良人员</el-breadcrumb-item
        >
      </el-breadcrumb>
    </div>
    <div class="card-box">
      <div class="screen-box">
        <div class="screen-input-box">
          <div class="title-box">身份证号:</div>
          <el-input
            v-model="idCard"
            placeholder="身份证号"
            clearable
          ></el-input>
        </div>
        <div class="screen-input-box">
          <div class="title-box">姓名:</div>
          <el-input v-model="name" placeholder="姓名" clearable></el-input>
        </div>
        <div class="screen-input-box">
          <div class="title-box">护照号:</div>
          <el-input
            v-model="passportCode"
            placeholder="护照号"
            clearable
          ></el-input>
        </div>
        <div class="screen-input-box">
          <div class="title-box">签证类型:</div>
          <el-select
            v-model="sponsor"
            class="card-select"
            placeholder="签证类型"
            filterable
            clearable
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <button class="btn-pre" @click="getBlacklists(1)">
          <i class="fa fa-search"></i>查询
        </button>
        <button class="btn-add btn-pre" @click="newBuilt">
          <i class="fa ion-plus-round"></i>新建
        </button>
        <button class="btn-pre" @click="dialogVisible2 = true">
          <i class="fa fa-upload"></i>导入
        </button>
        <button class="btn-pre" @click="exportBlacklist">
          <i class="fa fa-download"></i>导出
        </button>
      </div>
      <div class="table-box">
        <el-table
          :data="tableData"
          height="500"
          border
          tooltip-effect="light"
          :header-cell-style="{ color: '#f5f5f5', background: '#23527c' }"
          :empty-text="loading ? '加载中...' : '暂无数据'"
        >
          <el-table-column label="#" width="55">
            <template slot-scope="scope">
              <el-checkbox></el-checkbox>
            </template>
          </el-table-column>
          <el-table-column prop="NameinChinese" label="姓名" width="80" />
          <el-table-column prop="NameinEnglish" label="英文名" width="120">
          </el-table-column>
          <el-table-column prop="Gender" label="性别" width="60">
            <template slot-scope="scope">
              <span v-if="scope.row.Gender == 1">男</span>
              <span v-if="scope.row.Gender == 2">女</span>
            </template>
          </el-table-column>
          <el-table-column prop="NationalityName" label="国籍" width="60" />
          <el-table-column
            prop="ResidenceAddress"
            label="户籍地址"
            width="100"
          />
          <el-table-column
            prop="IDofHomeCountry"
            label="身份证号"
            width="120"
          />
          <el-table-column prop="ProjectName" label="项目名称" width="120" />
          <el-table-column
            prop="WorkingUnitName"
            label="用工单位"
            width="120"
          />
          <el-table-column
            prop="JobProfessionName"
            label="岗位名称"
            width="120"
          />
          <el-table-column prop="PassportCode" label="护照号" width="115" />
          <el-table-column prop="SPONSORName" label="签证类型" width="100" />

          <el-table-column prop="EntryDate" label="入沙时间" width="110">
            <template slot-scope="scope">
              <span v-if="scope.row.EntryDate">
                {{ scope.row.EntryDate.slice(0, 10) }}
              </span>
              <span v-else>无</span>
            </template>
          </el-table-column>
          <el-table-column prop="ExitDate" label="离沙时间" width="110">
            <template slot-scope="scope">
              <span v-if="scope.row.ExitDate">
                {{ scope.row.ExitDate.slice(0, 10) }}
              </span>
              <span v-else>无</span>
            </template>
          </el-table-column>

          <el-table-column
            prop="AbnormalExitReasons"
            label="离境原因"
            width="100"
          />
          <el-table-column prop="Remark" label="备注" width="100" />
          <el-table-column label="操作" width="100">
            <template slot-scope="scope">
              <i class="fa fa-edit" @click="edit(scope.row)"></i>
              <i
                class="fa fa-trash"
                @click="deleteBlacklistItem(scope.row)"
              ></i>
            </template>
          </el-table-column>
        </el-table>

        <el-pagination
          @size-change="getBlacklists(1)"
          @current-change="getBlacklists"
          :current-page.sync="page"
          :page-size.sync="size"
          :page-sizes="[10, 20, 50, 100]"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
    <el-dialog
      title=" 不良人员信息记录"
      :visible.sync="dialogVisible"
      width="99vw"
      :before-close="handleClose"
      top="1vh"
    >
      <div class="dialog-main">
        <Blacklist
          ref="blacklist"
          @update="getBlacklists"
          @close="handleClose"
        ></Blacklist>
      </div>
      <div class="dialog-footer">
        <el-button class="pop-close" @click="handleClose">关闭</el-button>
        <el-button class="pop-save" @click="save">保存</el-button>
      </div>
    </el-dialog>
    <el-dialog
      title="不良人员信息记录导入"
      :visible.sync="dialogVisible2"
      width="600px"
      :before-close="handleClose2"
      top="50px"
      class="dialog"
    >
      <div class="text-box">
        <div class="text-title">模板文件</div>
        <div class="text-name" @click="getDownloadUrl(downloadUrl)">
          不良人员信息记录导入模板
        </div>
      </div>
      <div class="upload-box2">
        <div class="left">
          <div v-if="name2" class="name">
            <svg-icon class="icon" icon-class="glyphicon-file"></svg-icon>
            {{ name2 }}
          </div>
        </div>
        <el-upload
          action="#"
          class="card-upload"
          :auto-upload="true"
          :http-request="uploadAttachment"
          ref="uploadRef"
        >
          <button class="btn-up">
            <svg-icon class="icon" icon-class="file"></svg-icon>选择 ...
          </button>
        </el-upload>
      </div>
      <div class="dialog-footer2">
        <el-button class="pop-close" @click="handleClose2">关闭</el-button>
        <el-button class="pop-save pop-right" @click="importBlacklist"
          >导入</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { deleteAttachment, uploadAttachment } from "@/api/user";
import {
  getBlacklists,
  importBlacklistTemplate,
  importBlacklist,
  exportBlacklist,
  getBlackItem,
  deleteBlacklistItem,
} from "@/api/table";
import Blacklist from "@/components/tables/blacklist.vue";
import request from "@/utils/request";
export default {
  data() {
    return {
      tableData: [],
      page: 1,
      size: 10,
      total: 10,
      options: [
        {
          label: "SNEI",
          value: 1,
        },
        {
          label: "SEG",
          value: 2,
        },
        {
          label: "其它",
          value: 3,
        },
      ],
      powers: {
        add: false,
        update: false,
        delete: false,
        download: false,
        import: false,
        export: false,
        print: false,
        examine: false,
        upload: false,
        resetting: false,
      },
      dialogVisible: false,
      dialogVisible2: false,
      dialogVisible3: false,
      loading: true,
      name: null,
      idCard: null,
      userName: null,
      passportCode: null,
      sponsor: null,
      uploadUrl: null,
      isEdit: 1,
      name2: null,
      name3: null,
      link: null,
      parmse: null,
      percentage: 0,
      isPercentage: false,
    };
  },
  components: { Blacklist },
  methods: {
    handleClose() {
      this.dialogVisible = false;
      this.disabled = false;
      this.$refs.blacklist.clearData();
    },
    handleClose2() {
      this.dialogVisible2 = false;
      this.name2 = null;
    },
    handleClose3() {
      this.dialogVisible3 = false;
      this.link = null;
      this.parmse = null;
    },
    newBuilt() {
      this.dialogVisible = true;
      this.isEdit = 1;
    },
    edit(data) {
      this.dialogVisible = true;
      this.isEdit = 2;
      this.getBlackItem(data.ID);
    },
    getButtonPower() {
      if (!this.$store.state.userPower) {
        this.$store.commit(
          "changeUserPower",
          JSON.parse(localStorage.getItem("userPower"))
        );
      }

      const item = this.$store.state.userPower.find((item) => {
        return item.Name === this.$route.meta.title;
      });
      const actions = item.Actions;

      actions.forEach((element) => {
        switch (element.Id) {
          case 1: //增加
            this.powers.add = true;
            break;
          case 2: //删除
            this.powers.delete = true;
            break;
          case 3: //修改
            this.powers.update = true;
            break;
          case 5: //下载
            this.powers.download = true;
            break;
          case 6: //导入
            this.powers.import = true;
            break;
          case 7: //导出
            this.powers.export = true;
            break;
          case 8: //打印
            this.powers.print = true;
            break;
          case 9: //审批
            this.powers.examine = true;
            break;
          case 10: //上传
            this.powers.upload = true;
            break;
          case 11: //重置
            this.powers.resetting = true;
            break;
        }
      });
    },
    //获取表格
    getBlacklists(type) {
      if (type) {
        this.page = type;
      }
      this.loading = true;
      const data = {
        page: this.page,
        size: this.size,
        name: this.name,
        idCard: this.idCard,
        passportCode: this.passportCode,
        sponsor: this.sponsor,
      };
      getBlacklists(data).then((res) => {
        if (res.status === 200) {
          this.tableData = res.response.data;
          this.total = res.response.dataCount;
        }
        this.loading = false;
      });
    },
    //保存
    save() {
      this.$refs.blacklist.addCostAllocation(this.isEdit);
    },
    //获取详情
    getBlackItem(key) {
      getBlackItem({ id: key }).then((res) => {
        if (res.status === 200) {
          this.$refs.blacklist.geteditData(res.response);
        }
      });
    },
    //删除
    deleteBlacklistItem(data) {
      let IDs = [];
      IDs.push(data.ID);
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        deleteBlacklistItem({ IDs }).then((res) => {
          if (res.status === 200) {
            this.$message.success("删除成功");
            this.getBlacklists();
          }
        });
      });
    },

    uploadAttachment(file) {
      const data = file.file;
      var img = data.name.substring(data.name.lastIndexOf(".") + 1);
      const suffix1 = img === "xls";
      const suffix2 = img === "xlsx";
      if (!suffix1 && !suffix2) {
        this.$message.error("只能上传后缀为xls、xlsx的文件!");
        return;
      }

      const formData = new FormData();
      formData.append("Files", data);
      uploadAttachment(formData).then((res) => {
        if (res.status === 200) {
          this.name2 = data.name;
          this.uploadUrl = res.response;
          console.log("this.name2", this.name2);
        }
      });
    },

    //下载附件
    getDownloadUrl(url) {
      location.href = url;
    },

    deleteTrash(data) {
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        if (data.row.ID) {
          deleteAttachment({ id: data.row.ID }).then((res) => {
            if (res.status === 200) {
              this.$message.success("删除成功");
              this.tableData2.splice(data.$index, 1);
            }
          });
        } else {
          this.$message.success("删除成功");
          this.tableData2.splice(data.$index, 1);
        }
      });
    },
    //导入
    importBlacklist() {
      const data = {
        Path: this.uploadUrl,
        KeyID: this.KeyID,
      };
      importBlacklist(data).then((res) => {
        if (res.status === 200) {
          this.$message.success("导入成功");
          this.handleClose2();
          this.getBlacklists();
        }
      });
    },
    //导出
    exportBlacklist() {
      const data = {
        name: this.name,
        deptName: this.deptName,
        userName: this.userName,
      };
      exportBlacklist(data).then((res) => {
        if (res.status === 200) {
          let url = res.response;
          let charIndex = url.indexOf("Download");
          url = url.substr(charIndex, url.length);
          url = url.replace(/\\/g, "/");
          location.href = "https://hrms.baishulai.com/" + url;
        }
      });
    },

    //附件上传
    image(data) {
      this.dialogVisible3 = true;
      this.parmse = data;
      console.log("data", this.parmse);
    },
    //上传附件2
    uploadAttachment2(file) {
      const data = file.file;
      var img = data.name.substring(data.name.lastIndexOf(".") + 1);
      const suffix = img === "pdf";
      if (!suffix) {
        this.$message.error("只能上传后缀为pdf的文件!");
        return;
      }
      const formData = new FormData();
      formData.append("Files", data);
      this.isPercentage = false;
      request({
        url: `/api/v1/form/attachment/upload`,
        method: "post",
        headers: {
          "content-type": "multipart/form-data",
        },
        data: formData,
        onUploadProgress: (progressEvent) => {
          this.isPercentage = true;
          this.percentage = parseInt(
            (progressEvent.loaded / progressEvent.total) * 100
          );
        },
      }).then((res) => {
        if (res.status === 200) {
          this.name3 = data.name;
          this.link = "https://hrms.baishulai.com/upload/" + res.response;
        }
      });
    },
    // 保存附件
    updateGovSystem() {
      this.parmse.Url = this.link;
      const data = [];
      data.push(this.parmse);
      updateGovSystem({
        list: data,
      }).then((res) => {
        if (res.status === 200) {
          this.$message.success("附件保存成功");
          this.handleClose3();
        }
      });
    },
  },

  created() {
    this.getButtonPower();
    this.getBlacklists();
    importBlacklistTemplate().then((res) => {
      if (res.status === 200) {
        this.downloadUrl = "https://hrms.baishulai.com/" + res.response;
      }
    });
  },
};
</script>

<style lang="scss" scoped>
.main {
  width: 100%;
  height: 100%;
  padding: 24px;
  box-sizing: border-box;
  background-color: #f7f8fa;
  .page-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .page-title {
      font-size: 18px;
      color: #000000;
    }
    .breadcrumb-active {
      color: #303133;
      font-weight: bolder;
      cursor: pointer;
      .fa {
        margin-right: 5px;
      }
    }
  }
  .card-box {
    width: 100%;
    // min-height: calc(100% - 48px);
    // height: calc(100% - 48px);
    background: #ffffff;
    border-radius: 2px 2px 2px 2px;
    opacity: 1;
    margin-top: 24px;
    box-sizing: border-box;
    padding: 0 0 24px 0;
    .screen-box {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      position: relative;
      padding: 0 10px;
      .screen-input-box {
        margin-top: 24px;
      }
      .btn-pre {
        margin-top: 24px;
      }
      .btn-add {
        margin-top: 24px;
      }
      .el-input {
        width: 150px;
        margin-right: 24px;
      }
      .el-button {
        margin-right: 24px;
      }
      .el-select {
        width: 150px;
        margin-right: 24px;
      }
      .el-date-editor {
        margin-right: 24px;
      }
      .senior {
        position: absolute;
        width: 100%;
        top: 40px;
        left: 0;
        background: #fefefe;
        border: 1px solid #eae8e8;
        border-top-width: 1px;
        border-top-style: solid;
        border-top-color: rgb(234, 232, 232);
        box-sizing: border-box;
        z-index: 999;
        padding: 0 24px 24px 24px;
        border-top: 0;
        box-shadow: 0 7px 18px -12px #bdc0bb;
        .from-boxs {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          .from-box {
            margin-top: 24px;
            .name {
              margin-bottom: 10px;
            }
            .el-select {
              width: 150px;
            }
            .el-input {
              width: 150px;
            }
          }
        }
        .buttons {
          display: flex;
          justify-content: flex-end;
          margin-top: 24px;
        }
      }
    }
    .table-box {
      width: 100%;
      // height: calc(100% - 70px);
      .el-table {
        margin-top: 24px;
        // height: calc(100% - 56px);
        min-height: 500px;
        box-sizing: border-box;
      }
      .el-pagination {
        margin-top: 24px;
      }
      .table-color {
        color: #0076ff;
      }
      .buttons {
        display: flex;
      }
      .fa {
        margin-right: 10px;
        color: #3c8dbc;
        cursor: pointer;
      }
      .fa:hover {
        color: #72afd2;
      }
    }
  }
  .dialog-main {
    border-bottom: 1px solid #f4f4f4;
    padding: 12px 24px 0 24px;
    width: 100%;
    box-sizing: border-box;
    .line-box {
      display: flex;
      justify-content: space-between;
      width: 100%;
      .from-box {
        display: flex;
        align-items: center;
        width: 330px;
        margin-bottom: 24px;
        .name {
          width: 80px;
          font-size: 16px;
          flex-shrink: 0;
        }
        .el-cascader {
          width: 250px;
        }
        .el-input {
          width: 250px;
        }
      }
    }
  }
  .dialog-footer {
    height: 60px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .el-button {
      height: 34px;
      padding: 0 20px;
      margin-right: 12px;
      margin-left: 0;
    }
  }
}
.text-box {
  display: flex;
  color: #000000;
  .text-title {
    margin-right: 24px;
  }
  .text-name {
    color: #0076ff;
    cursor: pointer;
  }
}
.upload-box2 {
  display: flex;
  height: 34px;
  margin-top: 24px;
  .left {
    width: 100%;
    border: 1px solid #d2d6de;
    border-right: none;
    .name {
      display: flex;
      align-items: center;
      height: 34px;
      color: #555;
      font-size: 14px;
      text-indent: 2px;
    }
  }
  .btn-up {
    padding: 0;
    margin: 0;
    border: none;
    width: 90px;
    flex-shrink: 0;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: #3c8dbc;
    border: 1px solid #367fa9;
    color: #fff;
    font-size: 14px;
    .icon {
      margin-right: 5px;
    }
  }
  .disabled {
    cursor: no-drop;
  }
  .btn-up:hover {
    border-color: #204d74;
    background-color: #367fa9;
  }
}
.dialog-footer2 {
  height: 60px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  .el-button {
    height: 34px;
    padding: 0 20px;
    margin-right: 12px;
    margin-left: 0;
  }
}
</style>
<style lang="scss" scoped>
::v-deep .el-table {
  width: 100%;
  .el-table__header-wrapper table,
  .el-table__body-wrapper table {
    width: 100% !important;
  }
  .el-table__body,
  .el-table__footer,
  .el-table__header {
    table-layout: auto;
    .yincang {
      width: 100px !important;
      min-width: 100px !important;
      max-width: 100px !important;
      .cell {
        width: 100%;
        box-sizing: border-box !important;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: inherit !important;
        white-space: nowrap !important;
      }
    }
  }
  .el-table__header {
    .yincang {
      width: 100px !important;
      min-width: 100px !important;
      max-width: 100px !important;
      .cell {
        width: 100%;
        box-sizing: border-box !important;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: inherit !important;
        white-space: nowrap !important;
      }
    }
  }
}
.progress-box {
  height: 24px;
  display: flex;
  width: 100%;
  align-items: center;
  .el-progress {
    width: 100%;
  }
  // margin-top: 24px;
}
.upload-box {
  display: flex;
  height: 34px;
  // margin-top: 24px;
  .left {
    width: 100%;
    border: 1px solid #d2d6de;
    border-right: none;
    .name {
      display: flex;
      align-items: center;
      height: 34px;
      color: #555;
      font-size: 14px;
      text-indent: 2px;
    }
  }
  .btn-up {
    padding: 0;
    margin: 0;
    border: none;
    width: 90px;
    flex-shrink: 0;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: #3c8dbc;
    border: 1px solid #367fa9;
    color: #fff;
    font-size: 14px;
    .icon {
      margin-right: 5px;
    }
  }
  .disabled {
    cursor: no-drop;
  }
  .btn-up:hover {
    border-color: #204d74;
    background-color: #367fa9;
  }
}
</style>
<style scoped>
.el-dialog__wrapper >>> .el-dialog__body {
  padding: 0;
  overflow: auto;
  border-top: 1px solid #f4f4f4;
}
.el-dialog__wrapper >>> .my-label {
  background: #f5f7fa !important;
}
.el-textarea >>> .el-textarea__inner {
  min-height: 100px !important;
}
.el-table >>> .el-table__placeholder {
  width: 23px;
}
.table-box >>> .el-table__empty-block {
  width: 100% !important;
}
.el-table >>> th.el-table__cell > .cell {
  font-weight: bolder;
  font-size: 12px;
  text-align: center;
  font-family: "Microsoft YaHei";
}

.el-table >>> th.el-table__cell {
  padding: 6px 0;
}

.el-table >>> .el-table__body .cell {
  text-align: center;
  font-size: 12px;
  color: #333;
}
.el-table >>> .el-table__cell {
  padding: 6px 0;
}
.approval {
  display: inline;
  padding: 2px 5px;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 3px;
  font-size: 12px;
}
.label-success {
  background-color: #00a65a;
}
.label-warning {
  background-color: #f39c12;
}
.fa {
  margin-right: 10px;
  color: #3c8dbc;
  cursor: pointer;
}
.fa:hover {
  color: #72afd2;
}
.screen-box >>> .el-input__inner {
  height: 34px;
  border-color: #d2d6de;
  border-radius: 0;
}
.screen-box >>> .el-input__inner:focus {
  height: 34px;
  border-color: #61a2c9;
  border-radius: 0;
}
.card-upload >>> .el-upload-list {
  display: none;
}
.dialog >>> .el-dialog__body {
  padding: 15px 15px 0 15px;
}
.tableData2 >>> .el-table__empty-block {
  width: 100% !important;
}
.card-select >>> .el-input__icon {
  line-height: 34px;
}
.downloadd {
  color: #519fff;
  cursor: pointer;
}
</style>

import { render, staticRenderFns } from "./blacklist.vue?vue&type=template&id=e95d6956&scoped=true"
import script from "./blacklist.vue?vue&type=script&lang=js"
export * from "./blacklist.vue?vue&type=script&lang=js"
import style0 from "./blacklist.vue?vue&type=style&index=0&id=e95d6956&prod&lang=scss&scoped=true"
import style1 from "./blacklist.vue?vue&type=style&index=1&id=e95d6956&prod&lang=scss&scoped=true"
import style2 from "./blacklist.vue?vue&type=style&index=2&id=e95d6956&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e95d6956",
  null
  
)

export default component.exports